/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react';
import Image from 'next/image';
import { useCallback, useRef } from 'react';

import { useIntersection } from '@common/hooks/useIntersection';
import { fadeIn } from '@common/keyframes';
import { useTrackModuleImpression } from '@common/tracking';
import { makeUrlAbsolute, sendGAEvent } from '@common/utlis';
import { container } from '@stories/atoms/Grid';
import { SectionStyled } from '@stories/atoms/Section/Section';
import { MEDIA_URL } from '@utils/constants';

import type { QuicklinksFragment } from '@gql/hygraph/graphql';

export type QuicklinksWithIcon = QuicklinksFragment;

const trackClick = ({ label, href }) => {
  sendGAEvent({
    category: 'Navigation',
    action: `Quicklink with Icon click`,
    label: `${label} - ${href}`
  });
};

const Quicklink: React.FC<QuicklinksWithIcon['links'][0]> = ({ color, label, href, image }) => {
  const theme = useTheme();

  const hoverCss = {
    '.label-word:last-child': {
      whiteSpace: 'nowrap',
      '::after': {
        color: theme.color.accent,
        // content: '">"',
        display: 'inline-block',
        fontSize: theme.font.size.copy,
        marginLeft: '.25rem',
        transform: 'translateY(1px)',
        transition: 'transform 175ms ease-out'
      }
    },
    ':hover': {
      '.icon': {
        opacity: 0.8,
        transform: 'scale(1.15)'
      },
      '.label': {
        color: theme.color.hover.copy
      },
      '.icon-wrapper': {
        background: `radial-gradient(2.5rem at center, hsl(0 0% 100% / 60%), transparent), ${color?.css}`
      },
      '.label-word:last-child': {
        '::after': {
          transform: 'translate(3px, 1px)'
        }
      }
    }
  } as const;

  return (
    <a
      href={makeUrlAbsolute(href ?? '/')}
      css={{ display: 'block', textAlign: 'center', ...hoverCss }}
      onClick={() => trackClick({ label, href })}
    >
      <div
        className="icon-wrapper"
        css={(t) => ({
          border: 'thin solid hsl(0 0% 0% / 8%)',
          borderRadius: '100%',
          backgroundColor: color?.css ?? t.color.grey.g97,
          height: '5rem',
          margin: 'auto',
          position: 'relative',
          width: '5rem'
        })}
      >
        {image && (
          <Image
            className="icon"
            css={(t) => ({
              color: t.color.primary,
              left: 'calc(50% - 12px)',
              position: 'absolute',
              top: 'calc(50% - 12px)',
              transition: 'transform 175ms ease-out, opacity 175ms ease-out'
            })}
            src={`${MEDIA_URL}/${image.url}`}
            alt={image.alt}
            unoptimized
            width={24}
            height={24}
          />
        )}
      </div>
      <div
        className="label"
        css={(t) => ({
          fontSize: t.font.size.footnote,
          fontWeight: 'bold',
          lineHeight: '115%',
          marginTop: '.5rem'
        })}
      >
        {label.split(' ').map((word, index) => (
          <span key={word + index} className="label-word">
            {word}{' '}
          </span>
        ))}
      </div>
    </a>
  );
};

const QuicklinksWithIcon: React.FC<QuicklinksWithIcon> = ({ headline, links }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  // legacy tracking
  const intersectionCallback = useCallback(() => {
    sendGAEvent({ category: 'Module', action: 'Impression', label: 'Quicklinks with Icon' });
  }, []);
  useIntersection(containerRef, intersectionCallback, {
    name: 'noThreshold',
    rootMargin: '0px',
    threshold: 0.3
  });

  // new tracking
  const { viewed } = useTrackModuleImpression({ ref: containerRef, label: 'Quicklinks with Icon' });

  return (
    <SectionStyled headline={headline ?? undefined} attrs={{ id: 'good-for' }} spacingSize="short">
      <div css={container} ref={containerRef}>
        <ul css={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '1.25rem 2rem' }}>
          {links?.map((link, index) => (
            <li
              key={link.label}
              css={{
                flexShrink: 0,
                width: '115px',
                ...(viewed
                  ? { animation: `${fadeIn} 350ms ${~~(index / 2) * 150 + 50}ms ease-out both` }
                  : { opacity: 0 })
              }}
            >
              <Quicklink {...link} />
            </li>
          ))}
        </ul>
      </div>
    </SectionStyled>
  );
};

export default QuicklinksWithIcon;
